import type { I18nLocale } from "@/modules/i18n/types"
import type { FunctionComponent } from "react"

// The usage of next/link is explicitely needed here.
// eslint-disable-next-line no-restricted-imports
import NextLink from "next/link"

import { AVAILABLE_LOCALES } from "@/modules/i18n/constants"

export type LinkProps = Omit<Parameters<typeof NextLink>[0], "locale">

// The prefetch is disabled by default to avoid extra page load.
// The locale feature is not used
export const Link: FunctionComponent<LinkProps> = ({ prefetch = false, ...props }) => {
  const { href } = props

  // If the href is a string and does not contain the locale
  if (
    typeof href === "string" &&
    href !== "/" &&
    !AVAILABLE_LOCALES.find(availableLocale => href.startsWith(`/${availableLocale}`))
  ) {
    throw Error(`Missing an available locale in href of the link "${href}".`)
  }

  // If the href is an Url object and its pathname is a dynamic segment and the query does not contain an available locale
  if (
    typeof href !== "string" &&
    href.pathname?.startsWith("/[pageLocale]/") &&
    typeof href.query !== "string" &&
    !AVAILABLE_LOCALES.includes(`${href.query?.pageLocale}` as I18nLocale)
  ) {
    throw Error(`Missing an available locale in the query of the link "${JSON.stringify(href)}".`)
  }

  // If the href is an Url object and its pathname is NOT a dynamic segment and the query does not contain an available locale
  if (
    typeof href !== "string" &&
    !href.pathname?.startsWith("/[pageLocale]/") &&
    href.pathname !== "/" &&
    !AVAILABLE_LOCALES.find(availableLocale => href.pathname?.startsWith(`/${availableLocale}`))
  ) {
    throw Error(`Missing an available locale in the href.pathname of the link "${JSON.stringify(href)}".`)
  }

  return <NextLink {...props} prefetch={prefetch} />
}
